import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GraphQLErrorList } from '../components/graphql-errors'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import HubspotForm from 'react-hubspot-form'
import { Helmet } from 'react-helmet'

export const query = graphql`
query IndexPageQuery {
  heroImg: file(relativePath: {eq: "hero.svg"}) {
    publicURL
  }
 }
`

const IndexPage = ({ data, errors }) => {
  return (
    <main className='h-screen bg-gradient-to-r to-[#01579b] from-[#e1f5fe]'>
      <Helmet title='Salzify · Streamline your wholesale workflow'>
        <meta name='description' content='Streamline your wholesale workflow' />
      </Helmet>

      <section className='w-full px-8 text-gray-700 bg-white shadow-md'>
        <div
          className='flex flex-col  items-center justify-between py-5 w-full sm:flex-row'
        >
          <div className='flex flex-col sm:flex-row'>
            <Link
              to='#'
              className='flex items-center mb-5 font-medium text-gray-900 lg:w-auto lg:items-center lg:justify-center md:mb-0'
            >
              <span className='mx-auto text-xl font-black leading-none text-[#01579b] select-none'>Salzify</span>
            </Link>
          </div>
          <div className='flex flex-col sm:flex-row items-center w-full md:w-auto ml-0 md:ml-5 sm:justify-end'>
            {/* <Link */}
            {/*  to='/eng-apply' */}
            {/*  className='flex items-center px-4 py-3 mb-3 text-md text-gray-800 md:mb-0 md:w-auto rounded-full' */}
            {/* > */}
            {/*  We are hiring! */}
            {/*  <svg className='h-8 w-8 stroke-current' viewBox='0 0 24 24' fill='none'> */}
            {/*    <path */}
            {/*      d='M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z' /> */}
            {/*  </svg> */}
            {/* </Link> */}
            <AnchorLink
              href='#waitlist-form'
              className='flex items-center px-6 py-3 mb-3 text-lg text-white bg-purple-500 md:mb-0 hover:bg-purple-700 md:w-auto rounded-full'
            >
              <div className='flex items-center sm:min-w-max'>
                Join the waitlist
                <svg
                  xmlns='http://www.w3.org/2000/svg' className='w-5 h-5 ml-1' viewBox='0 0 24 24' fill='none'
                  stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'
                >
                  <line x1='5' y1='12' x2='19' y2='12' />
                  <polyline points='12 5 19 12 12 19' />
                </svg>
              </div>
            </AnchorLink>
          </div>
        </div>
      </section>

      <section className='container flex flex-col items-center md:flex-row px-2 pt-32 md:px-0 mx-auto'>
        <div className='container items-center max-w-6xl px-5 space-y-6 text-center'>
          <h1
            className='text-4xl font-extrabold tracking-tight text-left text-gray-900 sm:text-5xl md:text-6xl md:text-center'
          >
            Streamline your wholesale workflow
          </h1>
          <div className='relative flex flex-col justify-center md:flex-row md:space-x-4'>
            <AnchorLink
              href='#waitlist-form'
              className='flex items-center px-6 py-3 mb-3 text-lg text-white bg-purple-500 md:mb-0 hover:bg-purple-700 md:w-auto rounded-full'
            >
              <div className='flex items-center sm:min-w-max'>
                Join the waitlist
                <svg
                  xmlns='http://www.w3.org/2000/svg' className='w-5 h-5 ml-1' viewBox='0 0 24 24' fill='none'
                  stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'
                >
                  <line x1='5' y1='12' x2='19' y2='12' />
                  <polyline points='12 5 19 12 12 19' />
                </svg>
              </div>
            </AnchorLink>
          </div>
        </div>
        <div className='container items-center max-w-4xl px-5  mt-16 text-center'>
          <img src={data.heroImg.publicURL} />
        </div>
      </section>

      <section id='waitlist-form'
               className='container max-w-xl flex flex-col justify-center px-2 pt-16 sm:pt-96 lg:pt-64 md:px-0 mx-auto'>
        <h3 className='text-2xl text-gray-900 font-semibold'>Join the waitlist!</h3>
        <p className='text-gray-600'>We will call you soon!</p>
        <HubspotForm
          portalId='25313577'
          formId='9ddf2db3-1d27-4849-b098-e05a33b23035'
          region='eu1'
          loading={<div>Loading...</div>}
        />
        <div className='w-full'>
        </div>
        {/* <form className='form bg-white p-6 my-10 shadow-md'> */}
        {/*  <h3 className='text-2xl text-gray-900 font-semibold'>Join the waitlist!</h3> */}
        {/*  <p className='text-gray-600'>We will call you soon!</p> */}
        {/*  <div className='flex space-x-5 mt-3'> */}
        {/*    <input type='text' name='name' required id='' placeholder='Name*' */}
        {/*           className='form-input rounded border p-2  w-1/2' /> */}
        {/*    <input type='tel' name='mobile' id='' placeholder='Mobile' */}
        {/*           className='form-input rounded border p-2 w-1/2' /> */}
        {/*  </div> */}
        {/*  <input type='email' name='email' required placeholder='Email*' */}
        {/*         className='form-input rounded border p-2 w-full mt-3' /> */}
        {/*  <textarea name='message' id='' cols='10' rows='3' placeholder='Your message' */}
        {/*            className='form-textarea rounded border p-2 mt-3 w-full' /> */}
        {/*  <input type='submit' value='Submit' */}
        {/*         className='w-full mt-6 bg-blue-600 hover:bg-blue-500 text-white font-semibold p-3' /> */}

        {/* </form> */}
      </section>

      <section className='text-gray-700 body-font'>
        <div className='container flex flex-col items-center px-8 py-8 mx-auto max-w-7xl sm:flex-row'>
          <p className='mt-4 text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-200 sm:mt-0'>Salzify ©
            2021</p>

          <span className='inline-flex justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start'>
            <a href='https://www.linkedin.com/company/salzify' target='_blank'
               className='text-gray-400 hover:text-gray-500'>
              <span className='sr-only'>LinkedIn</span>
              <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'>  <path
                d='M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z' />
              <rect x='2' y='9' width='4' height='12' />  <circle cx='4' cy='4' r='2' />
            </svg>
            </a>

            {/* <a href='#' className='text-gray-400 hover:text-gray-500'> */}
            {/*  <span className='sr-only'>Twitter</span> */}
            {/*  <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'> */}
            {/*    <path */}
            {/*      d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' */}
            {/*    /> */}
            {/*  </svg> */}
            {/* </a> */}

            <a href='https://github.com/salzify' target='_blank' className='text-gray-400 hover:text-gray-500'>
              <span className='sr-only'>GitHub</span>
              <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'>
                <path
                  fillRule='evenodd'
                  d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
                  clipRule='evenodd'
                />
              </svg>
            </a>

          </span>
        </div>
      </section>
    </main>
  )
}

export default IndexPage
